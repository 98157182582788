import { useQuery } from '@apollo/client';
import EventHeader from 'components/EventHeader';
import EventAnimation from 'components/EventAnimation';
import Layout from 'components/Layout';
import React, { useEffect, useState } from 'react';
import { getOrder } from 'services/hasura';
import { navigate } from 'gatsby-link';
import { useQueryParam, StringParam } from 'use-query-params';
import Cart from 'components/Cart';
import { Box, Button, Heading } from 'grommet';

export default function complete() {
  const [id, setID] = useQueryParam('id', StringParam);
  const [order, setOrder] = useState({ status: null });
  const [orderStatus, setOrderStatus] = useState('');

  const [items, setItems] = useState([]);

  const resp = useQuery(getOrder(id), {
    // pollInterval: 5000,
    fetchPolicy: 'no-cache'
  });

  useEffect(() => {
    if (resp.loading) return;
    setOrder(resp.data?.order);
    setOrderStatus(resp.data?.order.status);
    setItems(resp.data?.order.items);
  }, [resp]);

  useEffect(() => {
    if (orderStatus === 'paid') {
      navigate(`/booking/complete/?id=${order.id}`, {
        replace: true
      });
    }
  }, [orderStatus]);

  return (
    <Layout>
      {resp.loading ? (
        <EventAnimation />
      ) : (
        <Box pad={{ vertical: 'medium' }} align="center" justify="center">
          {order?.event && <EventHeader event={order.event} />}
          {order && (
            <Box
              direction="row-responsive"
              pad={{ vertical: 'large', horizontal: 'medium' }}
              justify="between"
              align="center"
              gap="large"
            >
              <Box direction="column" align="center">
                <Box direction="column" gap="medium">
                  <Heading level={4}>Choose your payment method</Heading>
                  <Box gap="small">
                    <Box>
                      <Button
                        primary
                        label="Pay with MPESA"
                        href={`/booking/payment/?id=${id}&mode=mpesa`}
                      />
                    </Box>
                    <Box>
                      <Button
                        primary
                        label="Pay with Card"
                        href={`/booking/payment/?id=${id}&mode=card`}
                      />
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box width="smalllarge">
                <Cart
                  order={order}
                  items={items}
                  hideFooter
                  day={order.day}
                  days={order.days}
                  event={order?.event}
                />
              </Box>
            </Box>
          )}
        </Box>
      )}
    </Layout>
  );
}
